
.missionl{
    margin-left: 463px;
    max-width: 26rem;
}
.colors{
    color:rgb(80, 77, 77);
}


 
.column{
    margin-left:20px;
}
.submite2{
    display: flex;
   margin-left: 110px; 
}
.g-btn{
    margin: 20px;
}

.signupbutton{
    background-color: rgb(34, 146, 172); 
    border: none;
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
  
}