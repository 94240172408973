.bestmahilaloanberhampore{
    background-color: rgb(137, 0, 98);
}

.buttonhelpline{
    background-color: #170792; /* Green */
    border: none;
    color: white;
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
}

.navcolor a{
    color: #0e5b8b;
}


.navcolor{
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.colordrop a{
    color: #0e5b8b;
}