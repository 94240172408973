.MurshidabadZillaMahila{
    background-color: rgb(21, 122, 147);
}

.whatsappbutton{
    background-color: #054930; /* Green */
    border: none;
    color: white;
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
}


.blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }