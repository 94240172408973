.btn-grad41 {
    background-image: linear-gradient(to right, #08416a 0%, #6b0303  51%, #edd4e6  100%);
    margin: 0px;
    padding: 9px 29px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
  }
  
  .btn-grad41:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }


  .btn-grad42 {
    background-image: linear-gradient(to right, #0c5676 0%, #dfebef  51%, #164964  100%);
    margin: 0px;
    padding: 6px 18px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
  }
  
  .btn-grad42:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }


  .homecolornew{
    background-color: #d0dde7;
  }

  .murshidabadmahilaurban{
    background-color: #0e317c;
  }




  * {box-sizing: border-box}

  /* Container needed to position the overlay. Adjust the width as needed */
  .hoverimage {
    position: relative;
  
  }
  
  /* Make the image to responsive */
  .image1 {
    display: block;
    width: 100%;
    height: auto;
  }
  
  /* The overlay effect - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(4, 96, 104);
    background: rgba(11, 60, 81, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }
  
  /* When you mouse over the container, fade in the overlay title */
  .hoverimage:hover .overlay {
    opacity: 1;
  }

  .womenloanmurshidabad{
    background-color: rgb(137, 0, 98); 
    border: none;
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
  }

  .vechileloan h5{
    color: #1a547d;
  }

  .ptextcolor p{
    color: #6a022c;
  }

  .callbutton{
    background-color: rgb(34, 146, 172); 
    border: none;
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
  
  }



  .btn-grad33 {
    background-image: linear-gradient(to right, #7e590e 0%, #0c627e  51%, #725312  100%);
    margin: 0px;
    padding: 13px 15px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 3px;
    display: block;
  }
  
  .btn-grad33:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }